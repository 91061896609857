import { mainAdminSplitApi } from 'redux/store/mainSplitApi'
import { getSimpleGrade, type QueryParameters, type QueryResult } from 'types'
import { type Rasn, type RasnDetails } from 'types/RASN'

const rasnApi = mainAdminSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // Partial allows the query parameters to be optional, in which case they
    // are omitted from the request URI (which the backend knows how to handle)
    searchRasns: builder.query<QueryResult<Rasn>, Partial<QueryParameters<Rasn>>>({
      query: (parameters) => {
        return {
          url: '/rasns',
          params: parameters
        }
      },
      providesTags: ['SearchRasns']
    }),
    getRasn: builder.query<RasnDetails, string>({
      query: (rasnNumber) => {
        return {
          url: `/rasns/${rasnNumber}`
        }
      },
      transformResponse (response: RasnDetails): RasnDetails {
        return {
          ...response,
          units: response.units.map((unit) => {
            return {
              ...unit,
              simpleGrade: getSimpleGrade(unit.grade)
            }
          })
        }
      },
      providesTags: (result, error, rasnNumber) => [{ type: 'RasnDetails', id: rasnNumber }]
    }),
    unlockRasn: builder.mutation<void, string>({
      query: (rasnNumber) => {
        return {
          url: `/rasns/${rasnNumber}/unlock`,
          method: 'POST'
        }
      },
      invalidatesTags: (result, error, rasnNumber) => [{ type: 'RasnDetails', id: rasnNumber }, { type: 'SearchRasns' }]
    }),
    closeRasn: builder.mutation<void, string>({
      query: (rasnNumber) => {
        return {
          url: `/rasns/${rasnNumber}/close`,
          method: 'POST'
        }
      },
      invalidatesTags: (result, error, rasnNumber) => [{ type: 'RasnDetails', id: rasnNumber }, { type: 'SearchRasns' }]
    })
  })
})

export const { useSearchRasnsQuery, useGetRasnQuery, useUnlockRasnMutation, useCloseRasnMutation } = rasnApi
