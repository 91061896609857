import { type RasnSku, type RasnUnit } from './RASN'

export interface GoodsHolder {
  lpn: string
  rasn: string
  expectedQuantity: number
  actualQuantity: number
  status: GoodsHolderStatus
  handledBy: string
  lastModified: Date
}

export enum GoodsHolderStatus {
  INITIALIZED,
  OPEN,
  CLOSED
}

export interface GoodsHolderDetails {
  lpn: string
  expectedQuantity: number
  actualQuantity: number
  status: string
  handledBy: string
  lastModified: Date
  skus: RasnSku[]
  units: RasnUnit[]
}
