export enum RasnChannel {
  DIGITAL,
  NON_DIGITAL
}

export enum RasnStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export interface Rasn {
  number: string
  channel: RasnChannel
  holdCode: string
  customerName: string
  status: RasnStatus
}

export interface RasnDetails {
  number: string
  channel: RasnChannel
  holdCode: string
  customerName: string
  status: RasnStatus
  skus: RasnSku[]
  units: RasnUnit[]
  goodsholders: RasnGoodsholder[]
}

export interface RasnSku {
  sku: string
  upc: string
  hashable: boolean
  division: Division
  silhouette: string
  expectedQuantity: number
  actualQuantity: number
}

export interface RasnUnit {
  sku: string
  upc: string
  division: Division
  silhouette: string
  hashable: boolean
  coo: string
  grade: Grade
  simpleGrade: string
  gradeReason: string
  unitHandlingStation: string
  receivedGoodsHolder: string
  tote: string
  handledBy: string
  lastModified: Date
}
export interface RasnGoodsholder {
  lpn: string
  expectedQuantity: number
  actualQuantity: number
  status: string
  handledBy: string
  lastModified: Date
}

export enum Division {
  APPAREL,
  FOOTWEAR
}

export enum Grade {
  'A_GRADE',
  'B_GRADE',
  'C_GRADE'
}

export const getSimpleGrade = (grade: Grade): string => {
  if (typeof grade !== 'string') return ''
  switch (grade) {
    case 'A_GRADE':
      return 'A'
    case 'B_GRADE':
      return 'B'
    case 'C_GRADE':
      return 'C'
    default:
      return grade
  }
}

export enum GoodsholderStatus {
  'OPEN',
  'STARTED',
  'CLOSED'
}
